

const MainBody = () => {
    return (
        <div >
            
        </div>
    );
}


export default MainBody;