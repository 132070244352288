import "../src/";
import ReactDOM from "react-dom/client";

import NavBar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
import MainBody from "./MainBody";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <div>
        <NavBar />
        <MainBody />
        <Footer />
    </div>
);
