
const Header = () => {
    return (
        <div >
            
        </div>
    );
}

const NavBar = () => {
    return (
        <div>
            <Header />

        </div>
    );
}

export default NavBar; 
